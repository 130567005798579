/* eslint-disable no-extra-boolean-cast */
// eslint-disable-next-line import/extensions
import { authProvider } from "../configurations/authProvider";

export const logOutAndClearStorage = (): void => {
  localStorage.clear();
  sessionStorage.clear();
  authProvider.logoutRedirect();
};

export const getToken = async (
  requestedScopes = [
    "api://42706225-6803-4d2f-84c4-1b72fa65c985/access_as_user"
  ]
): Promise<any> => {
  await authProvider.handleRedirectPromise();

  const accounts = authProvider.getAllAccounts();

  if (accounts.length > 0) {
    const request = {
      scopes: requestedScopes,
      account: accounts[0]
    };

    return authProvider
      .acquireTokenSilent(request)
      .then(response => {
        return response.accessToken;
      })
      .catch(() => {
        return null;
      });

    // return accessToken;
  }

  return null;
};

export const getTokenRedirect = async (
  requestedScopes = [
    "api://42706225-6803-4d2f-84c4-1b72fa65c985/access_as_user"
  ]
): Promise<any> => {
  await authProvider.handleRedirectPromise();

  await authProvider
    .acquireTokenRedirect({
      scopes: requestedScopes
    })
    .then((response: any) => {
      return response.accessToken;
    });

  return null;
};

export const fetchWithAuth = async (
  fetch: any,
  url: any,
  options: any
): Promise<any> => {
  return getToken().then(async (token: any) => {
    if (!!token) {
      const o = options || {};
      if (!o.headers) o.headers = {};
      o.headers.Authorization = `Bearer ${token}`;

      return fetch(url, o);
    }

    await getTokenRedirect().then(async (tokenRedirect: any) => {
      const o = options || {};
      if (!o.headers) o.headers = {};
      o.headers.Authorization = `Bearer ${tokenRedirect}`;

      return fetch(url, o);
    });
  });
};
